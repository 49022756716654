<template>
	<div class="container-fluid contain">
		<div style="margin-left: 0; font-weight: bold; font-size: 18px; line-height: 22px;">Success</div>
		<div style="font-size: 14px; line-height: 17px;">Your order has been placed successfully!</div>
		<div class="row">
			<div class="col-xl-9 col-lg-12">
				<div class="item-cart">
					<div class="item-header">
						<div class="row w-100 p-0 m-0">
							<div class="col-xl-6 col-lg-12 d-flex flex-column m-0 p-0">
								<div class="d-flex align-items-center">
									<div style="width: 110px; font-size: 11px; line-height: 13px;">Delivery Date</div>
									<div style="font-size: 11px; line-height: 13px; font-weight: bold">{{moment(get(order, 'deliverBy')).format('MMMM D, YYYY')}}</div>
								</div>
								<div class="d-flex align-items-center" style="margin-top: 13px;">
									<div style="width: 110px; font-size: 11px; line-height: 13px;">Delivery To</div>
									<div style="font-size: 11px; line-height: 13px; font-weight: bold">{{get(order, 'deliverTo.name')}}</div>
								</div>
							</div>
							<div class="col-xl-6 col-lg-12 d-flex flex-column align-items-end justify-content-center p-0 m-0">
								<div class="d-flex align-items-center">
									<div style="width: 70px; font-size: 11px; line-height: 13px;">TIP</div>
									<div style="width: 70px; font-size: 11px; line-height: 13px;">TAX</div>
									<div style="font-size: 11px; line-height: 13px; font-weight: bold">TOTAL</div>
								</div>
								<div class="d-flex align-items-center" style="margin-top: 4px;">
									<div style="width: 70px; font-size: 11px; line-height: 13px;">${{get(order, 'cart.tip')}}</div>
									<div style="width: 70px; font-size: 11px; line-height: 13px;">${{get(order, 'cart.tax')}}</div>
									<div style="font-size: 11px; line-height: 13px; font-weight: bold">${{get(order, 'cart.total')}}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="item-body">
						<div class="row w-100 p-0 m-0">
							<div class="col-xl-3 col-lg-6 col-md-12 mt-2 mb-2 d-flex align-items-center" v-for="(item, index) in items" :key="index">
								<img :src="get(item, 'product.images[0].url', '')" style="width: 60px">
								<div style="margin-left: 8px;">
									<div style="font-weight: 500; font-size: 12px; line-height: 15px;">
										{{get(item, 'quantity')}} x {{get(item, 'product.name', '')}}
									</div>
									<div style="color: #C30000; font-size: 12px; line-height: 15px; font-weight: 500; margin-top: 1px;">${{item.priceEach || 0}}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="item-footer d-flex align-items-center">
						<div class="row w-100 m-0 p-0">
							<div class="col-xl-6 col-lg-12 d-flex align-items-center m-0 p-0">
								<div class="font-weight-bold" style="font-size: 12px; line-height: 13px; color: #808080">Order #{{get(order, 'id', '').substr(get(order, 'id.length') - 6).toUpperCase()}}</div>
								<div style="margin-left: 8px; background-color: #eaeaea; border-radius: 8px; padding: 0 8px; font-weight: 600; font-style: normal; font-size: 10px; line-height: 19px; color: #202020">
									{{getTitle(order)}}
								</div>
							</div>
							<div class="col-xl-6 col-lg-12 d-flex align-items-center justify-content-end m-0 p-0">
								<a style="font-style: normal; font-weight: 500; font-size: 11px; line-height: 13px; text-decoration-line: underline; color: #c30000" @click="$router.push({path: '/order-detail', query: isEvent ? {id: $route.query.id, event: 'hush'}: {id: $route.query.id}})">View Order Details</a>
								<img src="@/assets/images/icon_arrow_right.png" style="width: 13px; margin-left: 4px;">
							</div>
						</div>
					
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import {reactive, toRefs} from "@vue/composition-api";
  import {json} from 'overmind';
  import moment from 'moment';
  import {get} from 'lodash';

  export default {
    setup() {
      const data = reactive({
        order: {},
        items: [],
        deliverBy: false,
				moment,
				get,
      });
      const getTitle = (order) => {
        if (order.isDelivered || (!order.isCancelledByCustomer && !order.isCancelledByOperator && moment(order.deliverBy) < moment())) {
          return 'COMPLETED'

        } else if (order.isCancelledByCustomer || order.isCancelledByOperator) {
          return "CANCELED"

        } else {
          return "SCHEDULED"
        }
			};
      return {
        getTitle,
        ...toRefs(data)
      }
    },

    beforeMount() {
      this.order = json(this.state.order?.orders ? this.state.order?.orders[this.$route.query.id] : null);
      this.items = this.order?.cart?.items;
    },
    beforeCreate() {
      if (!this.state.currentUser?.id) {
        this.$router.push({path:'/', query: this.isEvent ? {event: 'hush'}: {}})
      }
    },
    beforeUpdate() {
      this.order = json(this.state.order?.orders ? this.state.order?.orders[this.$route.query.id] : null);
      this.items = this.order?.cart?.items;
    },
		computed: {
      isEvent() {
        return this.$route.query.event === 'hush';
			}
		}
  }
</script>

<style lang="css" scoped>
	.item-footer {
		border: 0 solid #D7D7D7;
		border-top-width: 1px;
		height: 40px;
		padding: 0 25px;
	}
	.item-body {
		padding: 20px 20px;
	}
	.item-header {
		height: 80px;
		background-color: #e4e4e4;
		display: flex;
		justify-content: space-between;
		border-radius: 3px 3px 0 0;
		align-items: center;
		padding: 0 24px;
		border: 1px solid #e4e4e4;
	}
	.item-cart {
		margin-top: 18px;
		background-color: #fbfbfb;
		border: 1px solid #cacaca;
		border-radius: 3px;
	}
	
	.item-body {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 10px;
	}
</style>
